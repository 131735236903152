import React,{Component} from 'react';
import Header2 from '../Header2';
import { Link } from 'react-router-dom';

const registerUrl = "https://newtonlogin.onrender.com/api/auth/register";


class Register extends Component {
    constructor(props){
        super(props)

        this.state={
            name:'',
            phone:'',
            email:'',
            password:''

        }
    }

    handleSubmit = () => {
        fetch(registerUrl,{
            method:'POST',
            headers:{
                'accept':'application/json',
                'content-type':'application/json'
            },
            body:JSON.stringify(this.state)
        })

        .then(this.props.history.push('/Admin'))
    }
   

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }
    render(){
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                            
                            
                        </div>
                    
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="background2">
                    <center>
                   
                        <div className="formdesign2">
                            <h5>Register</h5>
                            <form className="mainform2">
                                <input type="text" className="form-control mb-3" name="name" value={this.state.name} require placeholder='Input Name' onChange={this.handleChange}/>
                                <input type="number"className="form-control mb-3" name="phone" value={this.state.phone} require placeholder='Input Phone' onChange={this.handleChange}/>
                                <input type="email"className="form-control mb-3" name="email" value={this.state.email} require placeholder='Input Email' onChange={this.handleChange}/>
                                <input type="password"className="form-control mb-3" name="password" value={this.state.password} require placeholder='Input password' onChange={this.handleChange}/>
                                
                            </form>
                        
                            <center><button className="btn btn-primary btnwidth2" onClick={this.handleSubmit}>Register</button></center>
                        </div>
                    </center>
                           
                </div>
           
            </>
        )
    }
}

export default Register;
