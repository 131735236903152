import React,{Component} from 'react';
import Header2 from '../Header2';
import axios from 'axios';
import './Menu.css';
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format'; 




const vbookingUrl ="https://newtonparkmainapi.onrender.com/bookings"

class ViewBooking extends Component{

    constructor(props){
        super(props)
        console.log (">>>Inside VbCOnstructor")
        this.state={
            viewbook:''

        }
    }
    renderVbooking = (data) => {
        if (data){
            return data.map ((item) => {
                return (
                    <>
                        <tr key= {item.id}>
                            <td className="table-light table-striped adjust">{item.id}</td>
                            <td className="table-light table-striped adjust">{item.fname}</td>
                            <td className="table-light table-striped adjust">{item.lname}</td>
                            <td className="table-light table-striped adjust">{item.phone}</td>
                            <td className="table-light table-striped adjust">{item.email}</td>
                            <td className="table-light table-striped adjust">{item.roomType}</td>
                            <td className="table-light table-striped adjust">{item.arrival}</td>
                            <td className="table-light table-striped adjust">{item.departure}</td>
                            <td className="table-light table-striped adjust"><NumberFormat value={item.roomrate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</td>
                            <td className="table-light table-striped adjust">{item.stay}</td>
                            <td className="table-light table-striped adjust"><NumberFormat value={item.cost} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</td>

                        </tr>
                    </>
                     

                   
                )
            })
        }
    }

    render(){
        console.log (">>> Inside Vbdetails", this.state)
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                            
                            
                        </div>
                    
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="container">
                    <center><br/>
                        <h4>Reservations List</h4>
                    </center><br/>
                    <table className="table table-hover">
                        <thead className="table-warning">
                            <tr>
                                <th className="adjust">ID</th>
                                <th className="adjust">First Name</th>
                                <th className="adjust">Last Name</th>
                                <th className="adjust">Phone</th>
                                <th className="adjust">Email</th>
                                <th className="adjust">Room Type</th>
                                <th className="adjust">Arr. Date</th>
                                <th className="adjust">Dep. Date</th>
                                <th className="adjust">Room Rate</th>
                                <th className="adjust">No. of Nights</th>
                                <th className="adjust">Total Cost</th>
                            </tr>
                        </thead>
                        <tbody className="table table-hover">
                            {this.renderVbooking(this.state.viewbook)}
                        </tbody>
                    </table>
                    
                    <center>
                        <button className="btn btn-primary printing" onClick={()=>this.props.history.push('/Settings')}>Close</button>
                        <button className="btn btn-primary space printing" onClick={ () => window.print() }>Print</button>
                    </center>
                
                </div>

            </>
        )
    }
    async componentDidMount(){
        console.log (">>> Inside VbdidMount")
        const response = await axios.get(`${vbookingUrl}`)
        this.setState({viewbook:response.data})
      
      
    }
}
export default ViewBooking;