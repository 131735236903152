import React,{Component} from 'react';
import './Reservation.css';
import Header from '../Header';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'

const contUrl = "https://newtonparkmainapi.onrender.com/contact"

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:'',
            email:'',
            phone:'',
            Address:'',
            Subject:'',
            Message:'',
            startDate:'',
            endDate:'',
            pack:'',
            date:new Date(),
            Hall:''

        };
        this.starthandleChange = this.starthandleChange.bind(this);
        this.endhandleChange = this.endhandleChange.bind(this);
    }

    starthandleChange(date) {
        this.setState({
            startDate: date
        });
        
    }

    endhandleChange(date) {
        this.setState({
            endDate: date
        });
    }

    renderstartDate() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.starthandleChange}
                    minDate={new Date()}
                    maxDate={addDays(new Date(),26)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize51"
                    placeholderText="Event Start"
                />
            </div>
        )
    }

    renderendDate() {
        return (
            <div >
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.endhandleChange}
                    minDate={addDays(this.state.startDate,1)}
                    maxDate={addDays(new Date(),31)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    className="form-control mb-3 formsize52"
                    placeholderText="Event End"
                />
            </div>
        )
    }


    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    async handleSubmit() {
        
        try {

            var events = {
                email:`${this.state.email}`,
                phone:`${this.state.phone}`,
                Address:`${this.state.Address}`,
                Subject:`${this.state.Subject}`,
                Message: `${this.state.Message}`,
                startDate: `${this.state.startDate}`,
                endDate: `${this.state.endDate}`,
                pack: `${this.state.pack}`,
                date: moment(this.state.date).format('MMM-DD-YYYY')
               
            }

            let result = await fetch(contUrl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    events
                )
            });
            
            console.log('result>  ' + result)
            alert("Message Submitted Successfully")
            this.props.history.push('/')   
            
            
        } catch(e) {
            console.log(e)
        }
    }

    render(){                                                                                
        return (
            <>
                 <Header/>
                <div className="container">
                <div className="row">
                    <b><center><div className="meeetingtitle">Contact Us</div></center></b>
                    <div className="col-5 halldiv">
                       <p style={{marginTop: "280px"}}> Contact</p><p style={{marginTop: "-32px"}}>Us</p>
                    </div>
                    <div className="col-7 imgdiv">
                        <img src="https://i.ibb.co/SB2vKc6/IMG-4859-5-11zon.jpg" className="imgdiv" alt="hall"/>
                    </div>
                </div>
                    <div className="row">
                        <div className ="col-6 aboutfmt2">
                            <p className="formttxt4">Plot 24 Alexandra Close,. Off Ahmadu Bello Way, By Bannex Junction, Wuse 2, Abuja, Nigeria.</p>
                            <p className="formttxt5">annex@newtonparkhotels.com</p>
                            <center><p className="formttxt5">+234 803 595 2153, +234 803 503 5519, +234 803 221 4495</p></center>
                        </div>
                        <div className ="col-6 aboutfmt">
                            <input type="text" className="form-control mb-3" name="name" require placeholder= "First Name" value={this.state.name} onChange={this.handleChange}/>
                            <input type="email" className="form-control mb-3" name="email" require placeholder= "Last Name" value={this.state.email} onChange={this.handleChange}/>
                            <input type="number" className="form-control mb-3" name="phone" require placeholder= "Phone Number" value={this.state.phone} onChange={this.handleChange}/>
                            <input type="text" className="form-control mb-3" name="Address" require placeholder= "Enter Address" value={this.state.Address} onChange={this.handleChange}/>
                            <input type="text" className="form-control mb-3" name="Subject" require placeholder= "Enter Subject" value={this.state.Subject} onChange={this.handleChange}/>
                            <label>{this.renderstartDate(this.state.startDate)}</label>
                            <label>{this.renderendDate(this.state.endDate)}</label>
                            <select className="form-select mb-3" name="pack" onChange={this.handleChange}>
                                <option selected value=''>Select Hall</option>
                                <option value="Board Room">Board Room (50 PAX) N80,000.00</option>
                                <option value="Pool Area">Pool Area N100,000.00</option>
                               
                            </select>
                            <textarea className="form-control mb-3" rows="4" name="Message" require placeholder= "Enter Message" value={this.state.Message} onChange={this.handleChange}/>
                            <center><button disabled={this.state.name===''||this.state.phone===''||this.state.Subject===''}type="button" className="btn btn-secondary" onClick={ () => this.handleSubmit() }>Submit</button></center>
                        </div>
                                    
                    </div>
                </div>
            
            </>
        )
    }

}
export default Contact;