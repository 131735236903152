import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Header.css';
import {withRouter} from 'react-router-dom';


const url = "https://newtonparkmainapi.onrender.com/api/auth/userinfo";

class Header2 extends Component {
    constructor(props) {
        super()

        this.state={
            userdata:''
        }
    }
   handleLogout = () => {
        this.setState({userdata:''})
        localStorage.removeItem('userdata')
        localStorage.removeItem('rtk')
        this.props.history.push('/Admin')
    }

    condition1Header = () => {
        if(this.state.userdata.name){
            let data = this.state.userdata;
            let outputArray = [data.name,data.email,data.phone,data.role]
            localStorage.setItem('userdata',outputArray)
            return(
                <>
                    
                    <button type="button" className="btn btn-outline-* color">
                        <i className="fas fa-user-check color"></i> &nbsp;
                        <b>Hi, {outputArray[0]}</b>
                    </button>
                    <button type="button" className="logout btn btn-outline-danger" onClick={this.handleLogout}>
                        Logout
                    </button>
                    
                </>
            )
        }

        else{
            return(
                <>
                    <Link to="/Admin" className="buttoncntrl1 btn btn-outline-success ">Login</Link>
                    
                </>
            )
        }
    }

    render(){
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-dark">
                    <div className="container-fluid ">
                        <div className="navbar-brand">
                            <Link to="/">
                                <center><img src= "https://i.ibb.co/DMGydzX/logo.png" style={{width:"110px", height:"95px"}} alt="logo"/></center>
                            </Link>
                        </div>
                       
                        <div className="detext">
                           {this.condition1Header()}
                        </div>
                        
                    </div>
                </nav>
            
            </div>
        )
    }
    componentDidMount(){
        fetch(url, {
            method: 'GET',
            headers:{
                'x-access-token':localStorage.getItem('rtk')
            }
        })
        .then((res) => res.json())
        .then((data) => {
            this.setState({
                userdata:data
            })
        })
    }

    
}
export default withRouter (Header2);