import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Footer from './Footer';
import Home from './Home/Home';
import RoomDetails from './Details/RoomDetails';
import Listing from './Booking/Listing';
import Checkincheckout from './Booking/Checkincheckout';
import Explore from './Gallery/Explore';
import Halls from './Booking/Halls';
import Contact from './Booking/Contact';
import login from './Login/login';
import Menu from './Admin/Menu';
import register from './Login/register';
import ViewBooking from './Admin/ViewBooking';
import UpdateRooms from './Admin/UpdateRooms';
import RoomUpdate from './Admin/RoomUpdate';
import ContactMsg from './Admin/ContactMsg';
import video from './Admin/video';



const Routing = () => {
    return(
        <BrowserRouter>
            
            <Route exact path="/" component={Home}/>
            <Route path="/booking" component={Listing}/>
            <Route path="/rooms/:id" component={RoomDetails}/>
            <Route path="/checkIn/:rmName" component={Checkincheckout}/>
            <Route path="/explore" component={Explore}/>
            <Route path="/Events" component={Halls}/>
            <Route path="/Contact" component={Contact}/>
            <Route path="/Admin" component={login}/>
            <Route path="/Settings" component={Menu}/>
            <Route path="/adminlog" component={register}/>
            <Route path="/reservations" component={ViewBooking}/>
            <Route path="/rmupdate/:ruID" component={RoomUpdate}/>
            <Route path="/update" component={UpdateRooms}/>
            <Route path="/videoupdate" component={video}/>
            <Route path="/ContactNow" component={ContactMsg}/>
            <Footer/>
        </BrowserRouter>
    )
}

export default Routing;
