import React from 'react';
import './Reservation.css';
import {Link} from 'react-router-dom';
import Header from '../Header';

const Halls = () => {
    return(
        <>
            <Header/>
            <div className="container">
                <div className="row">
                    <b><center><div className="meeetingtitle">Meetings & Events</div></center></b>
                    <div className="col-5 halldiv">
                       <p style={{marginTop: "280px"}}> Meetings</p><p style={{marginTop: "-29px"}}> & Events</p>
                    </div>
                    <div className="col-7 imgdiv">
                        <img src="https://i.ibb.co/smQv9mg/5.jpg" className="imgdiv" alt="hall"/>
                    </div>
                </div>
                <div className="row largescrn">
                    <div className="col col md-12 xs-0  meetingcols meetingcols2">
                        <b><p className="evtag"> Event Planning</p></b>
                        <p>We provide conferencing solutions that will make your event planning a huge success.</p>
                        <p>In addition to this we provide comfortable accommodation that brings participants close to the event venue.</p>
                        <p>Moreso, we are a few minutes away from the airport. Contact us today and we will provide you with the perfect conferencing solution. </p>
                        <div>
                            <center><Link to="/Contact"><button type="button" class="btn btn-outline-dark">START PLANNING</button></Link></center>
                        </div>    
                    </div>


                    <div className="col col md-12 xs-0 meetingcols">
                        <b><p className="evtag"> Weddings</p></b>
                        <p>We have a team of specialists that will ensure that you get the best consultation.</p>
                        <p>All you need to do is click the link below to request a proposal that falls within your budget.</p>
                        <p>Planning should not be difficult when you plan your wedding with us today. We bring quality into your already tight budget. </p>
                        <div>
                            <center><Link to="/Contact"><button type="button" class="btn btn-outline-dark">GET A QUOTE</button></Link></center>
                        </div>  
                    </div>


                    <div className="col col md-12 xs-0 meetingcols">
                        <b><p className="evtag"> Catering</p></b>
                        <p>We ensure that we provide the right menu for the event. From continental to local dishes</p>
                        <p>Our team of experienced staff will work within your budget to make it a memory event. Nothing will be above your budget.</p>
                        <p>We leave a smile on your face and also on the faces of all your guests while making your event a success so click below to contact us.</p>
                        <div mb-3>
                            <center><Link to="/Contact"><button type="button" class="btn btn-outline-dark">CONTACT US</button></Link></center>
                        </div>  
                    </div>

                                        
                </div>

                

            </div>
        
        </>
    )
}

export default Halls;
